.center-aligns {
  display: flex;
  align-items: center;
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.space-between-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ant-select .ant-select-clear {
  top: 46% !important;
}
